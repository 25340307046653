import Image from 'next/image';
import PropTypes from 'prop-types';

const Icon = ({ className, src, width, height, alt, onClick, ...rest }) => {
    return (
        <Image
            loader={({ src }) => src}
            src={src}
            width={width}
            height={height}
            alt={alt}
            className={className}
            onClick={onClick}
            {...rest}
        />
    );
};

export default Icon;

Icon.defaultProps = {
    className: 'cursor-pointer',
    src: '/images/icons/profile.svg',
    width: 20,
    height: 20,
    alt: 'Icon',
    onClick: () => {},
};

Icon.propTypes = {
    className: PropTypes.string,
    src: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    alt: PropTypes.string,
    onClick: PropTypes.func,
};
