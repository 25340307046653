import PropTypes from 'prop-types';

const Heading = ({
    children,
    type,
    className,
    fontSize,
    fontWeight,
    fontColor,
    align,
    fontFamily,
}) => {
    const headingStyles = {
        h1: 'text-4xl/[38px] lg:text-5xl',
        h2: 'text-[1.75rem]/[36px] md:text-4xl/[38px]',
        h3: 'text-[28px]/[36px]',
        h4: 'text-xl/[24px] md:text-2xl/[28px]',
        h5: 'text-sm md:text-lg/[24px]',
        h6: 'text-sm',
    };

    const Type = headingStyles[type] ? type : 'h1';
    const fontSizeStyle = fontSize ? fontSize : headingStyles[type];

    return (
        <Type
            className={`${fontSizeStyle} ${className} ${fontWeight} ${fontColor} text-${align} ${fontFamily}`}
        >
            {children}
        </Type>
    );
};

Heading.defaultProps = {
    type: 'h1',
    className: '',
    fontSize: '',
    fontWeight: 'font-normal',
    fontColor: 'text-grey-shade1',
    align: 'left',
    fontFamily: 'font-heading',
};

Heading.propTypes = {
    type: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    className: PropTypes.string,
    fontSize: PropTypes.string,
    fontFamily: PropTypes.oneOf([
        'font-heading',
        'font-body',
        'font-sectionHeading',
    ]),
    fontWeight: PropTypes.oneOf([
        'font-light',
        'font-normal',
        'font-medium',
        'font-semibold',
        'font-bold',
        'font-black',
        '',
    ]),
    align: PropTypes.oneOf(['left', 'center', 'right']),
    children: PropTypes.node,
    fontColor: PropTypes.string,
};

export default Heading;
