const ApiUrl = process.env.NEXT_PUBLIC_API_URL;
const WordpressApiUrl = process.env.NEXT_PUBLIC_WORDPRESS_API_URL;

export const UrlParamsReplace = (url, params = {}, isWordpressApi = false) => {
    let urlWithPrefix = `${isWordpressApi ? WordpressApiUrl : ApiUrl}${url}`;
    if (params) {
        Object.keys(params).forEach(
            (key) =>
                (urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key]))
        );
    }
    return urlWithPrefix;
};
export const SEND_CHEMIST_OTP_URL = UrlParamsReplace('/admins/send-otp');
export const VERIFY_CHEMIST_OTP_URL = UrlParamsReplace('/admin/verify');

export const SIGNUP_LEAD_URL = UrlParamsReplace('/psoriasis/signup');
export const LOGIN_LEAD_URL = UrlParamsReplace('/psoriasis/login');
export const VERIFY_OTP_URL = UrlParamsReplace(
    '/psoriasis/leads/verify-otp-with-phone'
);
export const GET_PLAN_DETAILS = ([planId]) =>
    UrlParamsReplace(`/plans/${planId}`);

export const CREATE_EMAIL_SUBSCRIPTION = () =>
    UrlParamsReplace(`/psoriasis/email-subscriptions`);

export const GET_PLANS_URL = () => UrlParamsReplace(`/psoriasis/plans`);

// /psoriasis-website-blogs
export const GET_ALL_PSORIASIS_WEBSITE_BLOGS = () =>
    UrlParamsReplace(`/psoriasis-website-blogs`);

export const GET_PSORIASIS_WEBSITE_BLOG_ID = (id) =>
    UrlParamsReplace(`/psoriasis-website-blog-by-id?id=${id}`);

export const GET_ALL_PSORIASIS_WEBSITE_CATEGORIES = () =>
    UrlParamsReplace(
        `/pso-website-blog-category?_fields[]=name&_fields[]=slug&_fields[]=id&_fields[]=acf.order`,
        {},
        true
    );
export const ZOHO_GENERATE_ACCESS_TOKEN_URL =
    UrlParamsReplace(`/zoho-access-token`);
export const ZOHO_CREATE_TICKET_URL = UrlParamsReplace(`/zoho-tickets`);
export const ZOHO_UPLOAD_FILES_URL = UrlParamsReplace(`/zoho-uploads`);

export const PSORIASIS_WEBSITE_TESTIMONIAL_GET_URL = UrlParamsReplace(
    `/psoriasis-website-testimonials`
);
export const GET_PSORIASIS_WEBSITE_FAQS_URL = UrlParamsReplace(
    '/psoriasis-website-faqs'
);
export const CREATE_PSORIASIS_WEBSITE_FAQS_URL = UrlParamsReplace(
    '/psoriasis-webiste-faqs'
);

export const GET_ALL_PSORIASIS_WEBSITE_FAQS_CATEGORIES = () =>
    UrlParamsReplace(
        `/pso-website-faq-category?_fields[]=name&_fields[]=slug&_fields[]=id&_fields[]=acf.order`,
        {},
        true
    );

//back-office-payment
export const GET_PSORIASIS_ORDER_LINK_URL = (id) =>
    UrlParamsReplace('/psoriasis/pso-order-link/:id', { id });

export const UPDATE_PSORIASIS_ORDER_LINK_URL = (id) =>
    UrlParamsReplace('/psoriasis/pso-order-link/:id', { id });

export const RESEND_PSORIASIS_PAYMENT_LINK_URL = ({ orderId }) =>
    UrlParamsReplace('/psoriasis/order/:orderId/payment-link', { orderId });

export const GET_LEATEST_LEAD_ORDER_URL = ({ leadId }) =>
    UrlParamsReplace('/lead/:leadId/order/latest', { leadId });
export const GET_HERO_BANNER_CONTENT_URL = () =>
    UrlParamsReplace(
        `/hero-banner?_fields=acf.bullet_points,acf.image,title&acf_format=standard`,
        {},
        true
    );

export const GET_HOME_PAGE_TESTIMONIAL_URL = () =>
    UrlParamsReplace(
        `/cms-testimonial?_fields=acf.sub_heading,acf.testimonials_data,acf.profile_image,acf.profile_name,acf.testimonial_subtext,title&acf_format=standard`,
        {},
        true
    );
export const GET_PLAN_BANNER_URL = () =>
    UrlParamsReplace(
        `/product-banner?_fields=acf.how_it_works_video,acf.care_plan,acf.device_only_plan,title&acf_format=standard`,
        {},
        true
    );

export const GET_PSORIASIS_CARE_LEVEL_URL = () =>
    UrlParamsReplace(
        `/3rd-banner?_fields=acf.type_of_slide,acf.slide_1_details,acf.slide_2_details,acf.slide_3_details,title&acf_format=standard`,
        {},
        true
    );

export const GET_UV_DEVICE_SECTION_URL = () =>
    UrlParamsReplace(
        `/2nd-banner?_fields=acf.type_of_slide,acf.heading,acf.image_1,acf.image_2,acf.bullets,acf.second_slide_bullets,acf.slide_3_details,title&acf_format=standard`,
        {},
        true
    );
