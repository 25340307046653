import PropTypes from 'prop-types';
import Icon from './Icon';
function Button({
    label,
    onClick,
    type,
    style,
    size,
    disabled,
    padding,
    btnFontWeight,
    btnClass,
    name,
    id,
    isIcon,
    iconSrc,
    iconWidth,
    iconHeight,
    ...rest
}) {
    const buttonStyles = {
        primary:
            'bg-blue hover:bg-blue focus:bg-blue active:bg-blue visited:bg-blue text-white',
        secondary:
            'bg-primary-default hover:bg-blue focus:bg-primary-default active:bg-primary-default visited:bg-primary-default text-white',
        ghost: 'bg-white text-blue outline outline-1 outline-blue hover:bg-blue focus:bg-transparent focus:text-blue active:bg-transparent visited:bg-transparent hover:text-white ',
        primaryGhost:
            'bg-white text-grey-shade3 outline outline-1 outline-grey-shade3 hover:bg-blue focus:bg-transparent focus:text-blue active:bg-transparent visited:bg-transparent hover:text-white ',
        borderLess: 'text-blue',
        ghostNormal:
            'bg-white text-blue outline outline-1 outline-blue hover:bg-grey-shade6 focus:bg-transparent focus:text-blue active:bg-transparent visited:bg-transparent hover:text-blue ',
    };

    const buttonSize = {
        small: 'py-1.5 px-4 text-sm',
        medium: 'px-4 py-3 md:py-2.5 md:px-6 text-xs md:text-sm',
        large: 'py-[1.125rem] px-[44px] text-base',
        large2: 'py-[1.125rem] px-[16px] md:px-[2.625rem] text-base',
        large3: 'py-[1.125rem] px-[2.25rem] md:py-2.5 md:px-6 text-base md:text-sm ',
        large4: 'px-3 xsm:px-4 py-3 text-sm md:py-[1.125rem] md:px-[2.25rem] md:text-base ',
        default: 'py-5 md:py-2.5 px-2 text-sm w-full md:w-[190px]',
    };

    const btnStyle = buttonStyles[style] || buttonStyles.primary;
    const btnSize = padding ? padding : buttonSize[size] || buttonSize.large;

    return (
        <button
            id={id}
            name={name}
            type={type}
            onClick={onClick}
            className={`rounded-full disabled:bg-grey-shade5 disabled:text-grey-shade4 disabled:outline-none flex-shrink-0 ${btnFontWeight} ${btnStyle} ${btnSize} ${padding} ${btnClass} ${isIcon && 'flex gap-2'}`}
            disabled={disabled}
            {...rest}
        >
            {isIcon && (
                <Icon
                    src={iconSrc}
                    width={iconWidth}
                    height={iconHeight}
                    alt="icon"
                />
            )}
            {label}
        </button>
    );
}

Button.defaultProps = {
    label: '',
    onClick: () => {},
    type: 'button',
    style: 'primary',
    disabled: false,
    padding: '',
    btnClass: '',
    btnFontWeight: 'font-medium',
    isIcon: false,
    iconSrc: '',
    iconWidth: 24,
    iconHeight: 24,
};

Button.propTypes = {
    label: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.string,
    style: PropTypes.oneOf([
        'primary',
        'ghost',
        'secondary',
        'primaryGhost',
        'borderLess',
        'ghostNormal',
    ]),
    size: PropTypes.oneOf(['small', 'medium', 'large', 'large2', 'default']),
    padding: PropTypes.string,
    btnFontWeight: PropTypes.string,
    btnClass: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    isIcon: PropTypes.bool,
    iconSrc: PropTypes.string,
    iconWidth: PropTypes.number,
    iconHeight: PropTypes.number,
};

export default Button;
